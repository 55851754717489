import React from "react";
import ReactGA from "react-ga4";
import {Helmet} from "react-helmet";
import TermsCondition from "../components/TermsAndCondition/TermsCondition";

ReactGA.send({ hitType: "pageview", page: document.location.pathname})

const TermsAndCondition = () => {
  return (
    <>
      <Helmet>
        <title>ChitChat Game - Terms and Conditions</title>
      </Helmet>
      <TermsCondition />
    </>
  );
};

export default TermsAndCondition;
