import React, { useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import styles from "./Faq.module.css";

const Faq = () => {
  const [activeFaq, setActiveFaq] = useState(0);
  const faqs = [
    {
      question: "How many time can I start and stop a game?",
      answer:
        "Many time as you wish. No limits!!!",
    },
    {
      question: "Are always the same question?",
      answer:
        "No, the game does not always ask the same question. It incorporates new questions and rotates them to provide a varied and engaging experience for the players.",
    }
  ];

  return (
    <section className={styles.wrapper} id="faq">
      <div className={styles.container}>
        <h2 className={styles.title}>Frequently Asked Questions</h2>
        <div className={styles.faqs}>
          {faqs.map((el, index) => (
            <div
              key={index}
              className={[
                styles.singleFaq,
                activeFaq === index && styles.activeSingleFaq,
              ].join(" ")}
            >
              <div className={styles.questionAndAnswer}>
                <p className={styles.question}>{el.question}</p>
                {activeFaq === index && (
                  <p className={styles.answer}>{el.answer}</p>
                )}
              </div>
              <div className={styles.arrowBox}>
                {" "}
                <MdKeyboardArrowRight
                  className={styles.arrow}
                  onClick={() => setActiveFaq(index)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
//    <div className={styles.faqs}>
//      {faqs.map((el, index) => (
//        <div
//          key={index}
//          className={[
//            styles.singleFaq,
//            activeFaq === index && styles.activeSingleFaq,
//          ].join(" ")}
//        >
//          <div className={styles.questionAndAnswer}>
//            <p className={styles.question}>{el.question}</p>
//            {activeFaq === index && <p className={styles.answer}>{el.answer}</p>}
//          </div>
//          <IoIosArrowDropdownCircle
//            className={styles.arrow}
//            onClick={() => setActiveFaq(index)}
//          />
//        </div>
//      ))}
//    </div>;
