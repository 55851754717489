import { Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import Home from "./Pages/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndCondition from "./Pages/TermsAndCondition";

function App() {

  const TRACKING_ID = "G-VVWR2G8RM5";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.send({ hitType: "pageview", page: document.location.pathname})

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
        <Route
          path="/termsAndCondition"
          element={<TermsAndCondition />}
        ></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
