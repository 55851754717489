import React from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import PrivacyAndPolicy from "../components/PrivacyPolicy/PrivacyAndPolicy";

ReactGA.send({ hitType: "pageview", page: document.location.pathname})

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>ChitChat Game - Privacy Policy</title>
      </Helmet>
      <PrivacyAndPolicy />
    </>
  );
};

export default PrivacyPolicy;
